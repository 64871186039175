html, 
body {
   height:100%;
   margin: 0;
}

#root{
  height:100%;
}


