.container {
  scroll-snap-align: start;
  background-size: cover;
  background-image: url("./back1.svg");
  height: 100%;
  width: 100%;
}

.app{
  overflow: scroll;
  height: 100%;
  scroll-snap-type: y mandatory;
  overflow-x:hidden;
}

.header-text{
  cursor: pointer;
  padding-top: 10px;
}

.header{
  padding-top: 2%;
  padding-left: 25%;
  padding-right: 25%;
  display: flex;
  font-family: 'Share Tech Mono', monospace;
  font-style: normal;
  justify-content: space-between;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.header-logo,.token-icon{
  width: 48px;
  height: 48px;
}

.content{
  padding-left: 15%;
  padding-top: 12%;
  color: #FFFFFF;
}

.content-d1{
  padding-left: 10px;
}

.overlord-wrapper{
  padding-top: 30px;
  font-family: 'Share Tech Mono', monospace;
}

.content-d2{
  font-size: 20px;
  padding-left: 12px;
  font-family: 'Share Tech Mono', monospace;
}
.overlord-text{
  font: 400;
  font-size: 22px;
  font-style: oblique;
  text-decoration: underline;
  line-height: 30px;
}
.padding-wrapper{
  margin-top: 5px;
}

.hacking{
  font-family: 'Share Tech Mono', monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 2em;
  text-transform: uppercase;
}


.contant-second{
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  height: 100%;
  background-color: black;
  color: #FFFFFF;
}

.clients-title{
  margin-bottom: 10px;
  padding-top: 8%;
  flex-direction: column;
  font-family: 'Share Tech Mono', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 55px;
  display: flex;
  align-items: center;
  text-align: center;
}

.footer-claim{
  margin-bottom: 20px;
}

.item-wrappaer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-text2{
  cursor: pointer;
  padding-top: 10px;
  padding-right: 15px;
}
.split{
  opacity: 0.5;
  margin-right: 15px;
  border-right: #eee 1px solid;
  height: 16px;
  margin-top: 10px;
}

.logo-line{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 60px;
}
.footer{
  display: flex;
  height: 100%;
  align-items: flex-end;
  margin-right: 20px;
  margin-bottom: 5px;
  margin-left: 20px;
  justify-content: space-between;
  font-family: 'Share Tech Mono', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.modal-wrapper{
  border: #eee 1px solid;
  width: 700px;
  height: 300px;
  display: flex;
  flex-direction: column;
  color: #FFFFFF;
  font-family: 'Share Tech Mono', monospace;
  padding: 20px;
}
.close-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.circle {
  background: #FFFFFF;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.title-row{
  color: #E4873A;
  padding: 15px;
  text-transform: uppercase;
}

.content-row{
  padding: 15px;
  height: 100%;
}
.footer-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-wrapper{
  cursor: pointer;
}

.typewriter{
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #E4873A;
  white-space: nowrap; /* Keeps the content on a single line */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #E4873A }
}

.content-word{
  word-break: break-all;
  line-height: 1.5;
}

.people-logo{
  border: solid 2px #FFFFFF;
  width: 120px;
  height: 100px;
  padding: 10px;
  border-radius: 100px;
}

.people-logo-icon{
  width: 100px;
  height: 100px;
}

.people-logo-wrapper{
  display: flex;
  flex-direction: row;
}

.team-job{
  margin-left: 20px;
}

.bird-logo{
  width: 120px;
}

.rent-logo{
  width: 150px;
}

.dogesr{
  padding-right: 30px;
}